/*Media styles for all pages without comments*/
@media (max-width: 1286px) {
  .gall {
    margin-left: 100px;
  }
}
@media (max-width: 1199px) {
  body {
    overflow-x: hidden;
  }
  .header__phone {
    margin: 20px auto 0 auto;
    display: block;
  }
  .header__phone p,
  .header__phone span {
    text-align: center;
  }
  .header .fly .wrapper {
    right: -200%;
  }
  .header .cloud-1 {
    top: 640px;
  }
  .header__call {
    margin-top: 5px;
    float: right;
    display: inline-block;
    margin-right: 18px;
  }
  .header__top-menu ul {
    margin-left: 50px;
  }
  .header::after {
    left: 28%;
    width: 38%;
  }
  .advantages__item {
    height: 480px;
    width: 23%;
  }
  .gall {
    margin-left: -25px;
  }
  .gallery .phone {
    margin-bottom: 25px;
  }
  .gallery::after {
    margin-top: 35px;
  }
  .prices__block .panel-body .button {
    display: block;
  }
  .footer .nav__block .phone {
    margin-top: 30px;
    float: right;
    margin-left: 0;
    margin-right: 15px;
  }
  .footer .nav__block .button {
    margin-top: 20px;
  }
  .footer::after {
    top: 350px;
  }
  .footer .copyright__block .copyright {
    margin-right: 200px;
  }
  .footer .copyright__block .policy {
    margin-right: 70px;
  }
  .content {
    background: url(../img/cloud-bg.png) no-repeat center -120px;
  }
}
@media (max-width: 991px) {
  .hamburger {
    display: block;
  }
  .header__phone {
    display: inline-block;
  }
  .header:after {
    display: none;
  }
  .header__phone {
    display: none;
  }
  .header__call {
    display: none;
  }
  .header .cloud-1 {
    top: 630px;
  }
  .header .cloud-2 {
    top: 650px;
  }
  .burger {
    margin: 50px 10px;
    float: right;
  }
  .header__offer {
    margin-left: auto;
    margin-right: auto;
  }
  .header .cloud-2 {
    top: 591px;
    right: 0;
  }
  .advantages__item {
    height: 400px;
    width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0;
  }
  .advantages .button {
    margin-top: 50px;
  }
  .etaps__img .arrow-right {
    background: url(../img/left-arrow.png) no-repeat center center;
    right: 390px;
  }
  .etaps__img .arrow-left {
    background: url(../img/right-arrow.png) no-repeat center center;
    left: 130px;
  }
  .etaps:after {
    bottom: -116px;
  }
  .gallery .title {
    margin-top: 150px;
  }
  .gall {
    margin-top: 125px;
    height: 250px;
    margin-left: 120px;
    display: block;
  }
  .prices__block .price {
    top: 60px;
    right: 50px;
  }
  .prices__block .old__price {
    right: 50px;
    top: 35px;
  }
  .blog__article {
    text-align: center;
  }
  .footer .nav__block .bottom__menu {
    display: none;
  }
  .footer .nav__block .phone {
    margin-top: 70px;
    margin-left: 100px;
    float: left;
  }
  .footer .nav__block .button {
    margin-top: 60px;
    margin-right: 30px;
  }
  .footer::after {
    top: 382px;
  }
  .footer .copyright__block .copyright {
    margin-right: 40px;
  }
  .footer .copyright__block .policy {
    margin-right: 30px;
  }
  .footer .copyright__block .develop {
    float: right;
    margin-top: 30px;
  }
  .content {
    background: url(../img/cloud-bg.png) no-repeat center -110px;
  }
  .content .article {
    text-align: justify;
  }
  .content .article__content .button {
    display: none;
  }
  .content .article__title {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .header .fly {
    display: none;
  }
  .header .cloud-1 {
    top: 570px;
  }
  .header .cloud-2 {
    top: 640px;
  }
  .header .cloud-3 {
    top: -650px;
  }
  .header .cloud-4 {
    top: -840px;
  }
  .ruslan-photo {
    display: none;
  }
  .advantages__item {
    width: 46%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .etaps .left,
  .etaps .right {
    float: none;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
  }
  .etaps .left .text,
  .etaps .right .text {
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }
  .etaps .title {
    margin-bottom: 10px;
  }
  .etaps__img {
    display: none;
  }
  .etaps__content .title {
    margin-top: 40px;
  }
  .feedback .multiple-items .item img {
    margin: 0 auto;
  }
  .gall {
    margin-left: auto;
    margin-right: auto;
  }
  .gall a img {
    width: 100%;
  }
  .prices__block .title {
    max-width: 50%;
  }
  .contacts #map .map__overlay {
    top: -350px;
    left: 0%;
    right: 0%;
    margin: 0 auto;
    text-align: center;
    z-index: 10;
  }
  .contacts #map ymaps {
    margin-top: 350px;
  }
  .footer .nav__block .phone {
    margin-left: 50px;
  }
  .footer .text {
    text-align: center;
  }
  .footer .copyright__block .policy,
  .footer .copyright__block .develop,
  .footer .copyright__block .copyright {
    display: block;
    float: none;
    text-align: center;
  }
  .footer .copyright__block .develop {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .footer .copyright__block .copyright {
    margin-bottom: 0;
    margin-right: 0;
  }
  .footer .copyright__block .policy {
    margin-top: 20px;
    margin-right: 0;
  }
  .content__item {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    padding: 0;
  }
  .content__item-text {
    text-align: justify;
  }
}
@media (max-width: 655px) {
  .footer:after {
    display: none;
  }
  .footer .nav__block .phone {
    margin-left: 20px;
  }
}
@media (max-width: 640px) {
  .footer .nav__block .phone {
    margin-top: 20px;
    margin-left: 40px;
    float: right;
  }
  .footer .nav__block .button {
    margin-right: -206px;
    margin-top: 86px;
  }
  .footer::after {
    display: none;
  }
}
@media (max-width: 600px) {
  .gallery .phone {
    display: block;
    text-align: center;
    margin-right: 0;
  }
  .gallery .title {
    text-align: center;
  }
  .gallery .title:after {
    margin: 12px auto;
  }
  .gallery .button {
    display: block;
  }
  .prices__block .panel-body .button {
    margin: 38px auto 0 auto;
  }
  .footer::after {
    top: 420px;
  }
}
@media (max-width: 550px) {
  .advantages__item {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .prices__block {
    padding: 50px 30px;
  }
  .prices__block .title {
    margin-bottom: 30px;
    max-width: 100%;
    margin-left: 0;
  }
  .prices__block .price {
    top: 97px;
    right: unset;
    display: none;
  }
  .prices__block .text {
    text-align: center;
  }
  .prices__block .old__price {
    display: none;
  }
  .prices__block .title {
    font-size: 32px;
    text-align: center;
  }
  .prices__block .panel-body {
    text-align: justify;
  }
  .prices__block .fa-angle-down {
    right: 30px;
    top: 70px;
  }
  .prices__block .panel-body .button {
    padding: 10px 40px;
  }
}
@media (max-width: 480px) {
  .advantages .button {
    font-size: 19px;
  }
}
@media (max-width: 450px) {
  .content .article {
    padding: 40px 15px;
  }
}
@media (max-width: 410px) {
  .header__offer {
    width: 95%;
  }
  .header__offer p {
    font-size: 15px;
  }
  .hamburger {
    right: 35px;
  }
  .overlay .closebtn {
    right: 20px;
  }
  .header__offer-title {
    font-size: 30px;
  }
  .footer .nav__block .footer__logo {
    margin-right: 0;
    display: block;
    text-align: center;
    float: none;
  }
  .footer:before {
    display: none;
  }
  .footer .nav__block .phone {
    margin-left: 0;
    float: none;
    text-align: center;
  }
  .footer .nav__block .button {
    margin-right: auto;
    margin-left: auto;
    display: block;
    float: none;
    margin-top: 25px;
  }
}
@media (max-width: 400px) {
  .header__offer .timer div {
    font-size: 28px;
    width: 55px;
  }
  .offer .cloud-2 {
    top: -553px;
  }
  .advantages .button {
    font-size: 23px;
  }
  .advantages__item {
    height: 100%;
  }
  .advantages__item .text {
    font-size: 18px;
  }
  .etaps__content .title .number {
    display: none;
  }
}
@media (max-width: 385px) {
  .header__offer p {
    padding: 15px 25px;
  }
}
@media (max-width: 370px) {
  .header__offer .timer {
    padding-left: 30px;
  }
}
@media (max-width: 350px) {
  .prices__block .title {
    font-size: 29px;
  }
  .header__offer-title {
    font-size: 27px;
  }
  .header__offer p {
    padding: 15px 13px;
  }
  .header__offer .timer {
    padding-left: 15px;
  }
  .contacts #map .map__overlay {
    padding: 45px 0;
  }
  .contacts #map .map__overlay .contacts__block .address,
  .contacts #map .map__overlay .contacts__block .phone,
  .contacts #map .map__overlay .contacts__block .mail,
  .contacts #map .map__overlay .contacts__block .social {
    margin-left: 0;
  }
  .contacts #map .map__overlay .contacts__block .address i,
  .contacts #map .map__overlay .contacts__block .phone i,
  .contacts #map .map__overlay .contacts__block .mail i,
  .contacts #map .map__overlay .contacts__block .social i {
    left: 15px;
  }
}
@media (max-width: 333px) {
  .header__offer .timer {
    padding-left: 0;
  }
  .blog .button {
    padding: 10px 70px;
  }
}
